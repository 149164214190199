<template>
  <div class="media-report">
    <property-title :backgroundTitle="backgroundTitle" :title="title" />
    <media-lists />
  </div>
</template>
<script>
import PropertyTitle from "../../components/common/PropertyTitle";
import MediaLists from "../../components/news/MediaLists";
export default {
  components: {
    PropertyTitle,
    MediaLists,
  },
  metaInfo: {
    title: '爱情地产',
    meta: [{
      name: 'keyWords',
      content: '爱情地产'
    },
    {
      name: 'description',
      content: ''
    }]
  },
  data() {
    return {
      backgroundTitle: "NEWS",
      title: "媒体报道",
    };
  },
};
</script>
<style lang="less" scoped>
.property-title {
  margin-bottom: 67px;
}
</style>
